import React from "react"
import {
    ReactiveBase,
    DataSearch,
    ReactiveList
} from '@appbaseio/reactivesearch';

import {graphql, Link, useStaticQuery} from 'gatsby'

import { format } from 'date-fns'

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import Container from '../components/container'
import MetaSchemaSEO from '../components/seo';

import * as styles from '../components/blog-post-preview.module.css'
import * as styles_search from './search.module.css'



export const query = graphql`
  query {
    strapi{ siteSetting {
      id
    }}
  }
`


// markup
const SearchPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <MetaSchemaSEO
        title = "Search"
      />
      <Container>
        <ReactiveBase
            app={process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME || 'NotSet'} //  app="carstore-dataset" from site_settings in strapi
            credentials= {process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS || "public_crochetandknitting:publicsearch" }
            url={process.env.CONTENTCURATOR_SITESEARCH_URL || "https://public-sitesearch-2.es.us-central1.gcp.cloud.es.io:9243"}>
            <DataSearch
                componentId="q"
                dataField="title"
                URLParams={true}
            />
            
            <h2>Search Results:</h2>
            <ReactiveList
                componentId="result"
                title="Results"
                dataField="title"
                from={0}
                size={5}
                pagination={true}
                react={{
                    and: ['q'],
                }}
                render={({ data }) => (
                  <div className={styles_search.root}>
                    <ReactiveList.ResultCardsWrapper>
                      <ul className={styles_search.grid}>
                        {data.map(item => (
                        <li key={`${item.id}_${item.slug}`}>
                            <Link className={styles.inList} to={getPageUrl(item)}>
                              <div className={styles_search.leadMediaThumb}>
                                {item.preview_image ?
                                  <img className={styles_search.img} src={item.preview_image} alt={`${item.primary_topic}`}/>
                                  : <img src="/images/placeholder.png" alt="" />
                                }
                              </div>
                              <div className={styles.text}>
                                <h3 className={styles.title}>{item?.title}</h3>
                                {item.excerpt && (
                                  <div className={styles.excerpt}>
                                    {item.excerpt}
                                  </div>
                                )}
                                {item.updated_at && (
                                  <div className={styles.date}>{format(item.updated_at, 'MMMM Do, YYYY')}</div>
                                )}
                              </div>
                            </Link>
                        </li>
                        ))}
                      </ul>
                    </ReactiveList.ResultCardsWrapper>
                  </div>
                )}
            />
        </ReactiveBase>
      </Container>
    </Layout>
  )
}

export default SearchPage
